<script lang="ts">
	import { createField } from 'felte';
	import * as _ from 'lodash-es';

	export let count = 4;
	export let name: string;
	export let hasError = false;

	export let color: 'lightgrey' | 'grey' = 'grey';

	export let disabled = false;

	const { field, onInput } = createField(name);

	const BACKSPACE_KEY = 'Backspace';
	const LEFT_ARROW_KEY = 'ArrowLeft';
	const UP_ARROW_KEY = 'ArrowUp';
	const RIGHT_ARROW_KEY = 'ArrowRight';
	const DOWN_ARROW_KEY = 'ArrowDown';

	const inputValues: string[] = Array.from({ length: count }, () => '');
	const inputElements: HTMLInputElement[] = [];

	$: onInput(inputValues.join(''));

	export function reset() {
		for (let i = 0; i < inputValues.length; i++) {
			inputValues[i] = '';
			if (inputElements[i]) {
				inputElements[i].value = '';
			}
		}

		if (inputElements[0]) {
			inputElements[0].focus();
		}
	}
	function setInputRef(el: HTMLInputElement, idx: number): void {
		inputElements[idx] = el;
	}

	function handleInputChange(e: Event, idx: number): void {
		const target = e.target as HTMLInputElement;
		const value = target.value;

		if (value.length > 1 || Number.isNaN(Number(value))) {
			target.value = inputValues[idx];
			return;
		}

		inputValues[idx] = value;

		if (value && idx < inputElements.length - 1) {
			inputElements[idx + 1].focus();
		}
	}

	function handleKeyDown(e: KeyboardEvent, idx: number): void {
		const currentEl = inputElements[idx];
		const prevEl = inputElements[idx - 1];
		const nextEl = inputElements[idx + 1];

		if (e.key === ' ') {
			e.preventDefault();
			return;
		}

		switch (e.key) {
			case BACKSPACE_KEY:
				e.preventDefault();
				currentEl.value = '';
				inputValues[idx] = '';

				if (currentEl.value === '' && prevEl) {
					prevEl.focus();
					prevEl.select();
				}
				break;
			case LEFT_ARROW_KEY:
				if (prevEl) prevEl.focus();
				break;
			case UP_ARROW_KEY:
			case DOWN_ARROW_KEY:
				e.preventDefault();
				break;
			case RIGHT_ARROW_KEY:
				if (nextEl) nextEl.focus();
				break;
		}
	}

	function handlePaste(e: ClipboardEvent, idx: number): void {
		e.preventDefault();

		if (_.isNil(e.clipboardData)) return;

		const pastedData = e.clipboardData.getData('text/plain');
		const pastedNumbers = pastedData.replace(/[^0-9]/g, '');

		let nextIdx = idx;

		for (const char of pastedNumbers) {
			if (nextIdx >= inputValues.length) {
				break;
			}

			inputValues[nextIdx] = char;
			inputElements[nextIdx].value = char;
			nextIdx++;
		}

		if (nextIdx < inputElements.length) {
			inputElements[nextIdx].focus();
		} else {
			inputElements[inputElements.length - 1].blur();
		}

		inputElements[inputElements.length - 1]?.focus();
	}
</script>

<div use:field class="flex justify-center gap-3 text-5xl">
	{#each inputValues as _, idx}
		<input
			class:bg-darkgrey={color === 'grey'}
			class:bg-label={color === 'lightgrey'}
			class={`${hasError ? 'border border-red text-red' : ''}
				w-20 select-none rounded-xl px-6 py-4 text-center focus-visible:outline-none`}
			type="tel"
			inputmode="numeric"
			pattern="[0-9]*"
			maxlength="1"
			{disabled}
			bind:this={inputElements[idx]}
			on:input={(e) => handleInputChange(e, idx)}
			on:keydown={(e) => handleKeyDown(e, idx)}
			on:paste={(e) => handlePaste(e, idx)}
			use:setInputRef={idx} />
	{/each}
</div>
